import { useEffect } from 'react';

import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';

function clarityInstall() {
  const config = marketing(getEnv());
  if (!config.registrationMarketingTrackingEnabled) return;

  /* eslint-disable */
  /* prettier-ignore */
  (function(c,l,a,r,i,t,y){
    // @ts-expect-error clarity tries to be fancy
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    // @ts-expect-error clarity tries to be fancy
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    // @ts-expect-error clarity tries to be fancy
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
  })(window, document, "clarity", "script", "frk6ykef95");
  /* eslint-enable */
}

export function ClarityInstall() {
  useEffect(() => {
    clarityInstall();
  }, []);
  return null;
}
