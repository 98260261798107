import {
  type ClientLoaderFunctionArgs,
  Link,
  Navigate,
  useLoaderData,
  useNavigate,
} from '@remix-run/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useEffectOnce, useTitle } from 'react-use';
import { type ExternalScriptsHandle } from 'remix-utils/external-scripts';
import useSWRImmutable from 'swr/immutable';
import { match } from 'ts-pattern';

import {
  type DtoGamePack,
  type DtoRegisterRequest,
  type ModelsPrice,
} from '@lp-lib/api-service-client/public';

import { useAuthAnalytics } from '../analytics/auth';
import { useUserAnalytics } from '../analytics/user';
import {
  EMAIL_PATTERN,
  type LoginState,
  usePostLogin,
} from '../components/Access';
import { useSlackInstalledCallbackModal } from '../components/Channel';
import { LegalDisclaimer } from '../components/common/Utilities';
import {
  ConfirmCancelModalProvider,
  ConfirmCancelModalRoot,
} from '../components/ConfirmCancelModalContext';
import { GamePackCover } from '../components/Game/Utilities';
import { GlobalLoading } from '../components/GlobalLoading';
import { Loading } from '../components/Loading';
import {
  OnboardingCompanies,
  OnboardingPageLayout,
} from '../components/Onboarding/OnboardingLayout';
import { type RegisterFor } from '../components/Onboarding/types';
import { OnboardingUtils } from '../components/Onboarding/utils';
import { ProvidersList } from '../components/ProvidersList';
import { SlackUtils } from '../components/Slack';
import { TagBackground, TagIcon } from '../components/Tagging';
import {
  useIsUserLoaded,
  UserContextProvider,
  useUser,
} from '../components/UserContext';
import { useLiveAsyncCall } from '../hooks/useAsyncCall';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { getQueryParam } from '../hooks/useQueryParam';
import { apiService } from '../services/api-service';
import { ClarityInstall } from '../tracking/clarity';
import { GAIdentify, gaScripts } from '../tracking/ga';
import {
  HubspotIdentify,
  hubspotScripts,
  HubspotTrackPageView,
} from '../tracking/hubspot';
import { type Tag } from '../types';
import { type GamePack } from '../types/game';
import { fromDTOGamePack } from '../utils/api-dto';
import { getStaticAssetPath } from '../utils/assets';
import { booleanify, err2s, makeTitle } from '../utils/common';

function useHandleRegister() {
  const navigate = useNavigate();
  const postLogin = usePostLogin();

  return useLiveCallback(async (req: DtoRegisterRequest) => {
    try {
      const resp = await apiService.auth.register(req);
      postLogin(resp.data);
    } catch (error) {
      const apiErr = apiService.utils.CastAPIError(error);
      if (apiErr?.msg === 'email_already_registered') {
        const state: LoginState = {
          error:
            'Oops! That email address is already associated with a Luna Park account. You can login below!',
        };
        const params = new URLSearchParams(window.location.search);
        params.set('email', req.email);
        navigate(
          {
            pathname: '/login',
            search: params.toString(),
          },
          {
            state,
            replace: true,
          }
        );
      } else if (apiErr?.msg === 'free_email_address') {
        throw new Error(
          'Please enter your company email address. Free email addresses are not permitted.'
        );
      } else {
        throw error;
      }
    }
  });
}

function RightPanel(props: { pack?: GamePack | null; tag?: Tag | null }) {
  const { pack, tag } = props;

  return (
    // Note(jialin): not sure why, but lg:block doesn't work. And lg:flex can not expand the space correctly
    <div className='hidden lg:flex flex-col items-center justify-center w-120'>
      {pack ? (
        <div className='w-full rounded-xl overflow-hidden'>
          <GamePackCover pack={pack} outerClassName='rounded-xl' />
        </div>
      ) : tag ? (
        <div className='relative w-full h-37'>
          <TagBackground
            tag={tag}
            className='absolute w-full h-full rounded-xl object-cover'
          />
          <div className='absolute w-full h-full flex flex-col justify-center items-center gap-4'>
            <TagIcon tag={tag} className='w-10 h-8 object-contain' />
            <h1 className='text-base font-bold text-white font-Montserrat'>
              {tag.name}
            </h1>
          </div>
        </div>
      ) : (
        <img
          src={getStaticAssetPath('images/onboarding/registration-14-03.png')}
          alt=''
          className='object-contain w-4/5'
        />
      )}
      <OnboardingCompanies className='mt-10 gap-5.5 filter grayscale' />
    </div>
  );
}

function ColdOutreachPanel() {
  const { youtubeVid } = useLoaderData<typeof clientLoader>();

  return (
    <div className='flex flex-col items-center text-white'>
      <div className='text-3.5xl font-bold'>Watch this first</div>
      {youtubeVid && (
        <div
          className='w-140 rounded-lg overflow-hidden mt-5'
          style={{
            aspectRatio: '16 / 9',
          }}
        >
          <iframe
            width={560}
            height={315}
            src={`https://www.youtube.com/embed/${youtubeVid}`}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            referrerPolicy='strict-origin-when-cross-origin'
            allowFullScreen
          ></iframe>
        </div>
      )}
      <OnboardingCompanies className='mt-10 gap-5.5 filter grayscale' />
    </div>
  );
}

function TrialOffer(props: {
  firstName: string;
  trialDays: number;
  onConfirm: () => void;
}) {
  const { firstName, trialDays, onConfirm } = props;

  return (
    <div
      className='w-full h-full flex justify-center items-center text-white overflow-auto scrollbar'
      style={{
        backgroundImage: `url(${getStaticAssetPath(
          'images/registration/trial-offer-bg.png'
        )})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='w-140 bg-black rounded-2.5xl border border-secondary px-14 py-9 flex flex-col items-center'>
        <div className='w-108 font-bold text-2xl text-center leading-tight'>
          <span className='text-tertiary'>{firstName}, </span>
          here is a {trialDays} day free trial for your team
        </div>
        <img
          alt=''
          src={getStaticAssetPath('images/registration/trial-offer.png')}
          className='mt-4 w-104 h-40 object-cover'
        />
        <div className='mt-5 text-center w-116 text-3.5xl font-bold leading-tight'>
          <span className='text-tertiary'>Unlimited </span>
          Access to 100+ Team Building Experiences
        </div>
        <button
          type='button'
          onClick={() => onConfirm()}
          className='mt-7.5 w-78 h-12.5 btn bg-green-001 hover:bg-[#33c35b] transition-colors rounded-xl flex items-center justify-center'
        >
          Get Started
        </button>
        <div className='mt-6 text-sms'>
          You will not be charged until the end of the {trialDays} day trial.
        </div>
      </div>
    </div>
  );
}

type FormData = {
  email: string;
};

export function RegistrationForm(props: {
  registerFor: RegisterFor | null;
  initEmail?: string | null;
  price: Nullable<ModelsPrice>;
  pack: Nullable<DtoGamePack>;
  headcount: Nullable<string>;
  onSubmit: (email: string) => Promise<void>;
  error?: Error | null;
}) {
  const { registerFor, initEmail, price, pack, headcount, onSubmit, error } =
    props;

  const analytics = useAuthAnalytics();

  const { register, handleSubmit, formState, setFocus } = useForm<FormData>({
    defaultValues: {
      email: initEmail || '',
    },
  });

  const handleFormSubmit = async (data: FormData) => {
    analytics.trackRegistrationFormSubmitted({
      method: 'email',
      search: window.location.search,
    });

    await onSubmit(data.email);
  };

  useEffectOnce(() => {
    setFocus('email');
  });

  const title = match(registerFor)
    .with('oneTimePurchase', () => (
      <>
        Let’s continue planning, <br />
        <span className='text-tertiary'>{pack?.name}</span>
      </>
    ))
    .with('demo', () => (
      <>
        Start your free demo of
        <br />
        <span className='text-tertiary'>{pack?.name}</span>
      </>
    ))
    .otherwise(() => (
      <>
        Boost team engagement <br /> in just a few clicks
      </>
    ));

  return (
    <div
      className='
          w-140 lg:bg-modal text-white 
          rounded lg:rounded-2.5xl 
          px-5 lg:px-17 py-0 lg:py-10
          flex flex-col gap-5
        '
    >
      <h2 className='text-2xl font-medium text-center leading-8'>{title}</h2>

      {pack && headcount && (
        <div className='w-full flex items-center justify-center'>
          <div className='bg-lp-gray-003 rounded px-2 py-1'>
            For {headcount} People
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <label className='w-full'>
          <p className='font-bold'>What is your work email?</p>
          <input
            className={`mt-2.5 w-full h-12.5 ${
              formState.errors.email ? 'field-error' : 'field'
            } mb-0`}
            placeholder='name@company.com'
            {...register('email', {
              required: true,
              pattern: EMAIL_PATTERN,
              maxLength: 60,
            })}
            maxLength={60}
          />
          <div className='mt-0.5 ml-1 h-3 w-full text-3xs text-red-002 text-left truncate'>
            {formState.errors.email && 'Please enter a valid email address'}
          </div>
        </label>

        <div className='ml-1 -mt-2 h-3 w-full text-3xs text-red-002 text-left truncate'>
          {err2s(error)}
        </div>

        <button
          type='submit'
          className={`mt-0.5 w-full btn-primary h-12.5 flex items-center justify-center`}
          disabled={formState.isSubmitting}
        >
          {formState.isSubmitting && (
            <Loading text='' containerClassName='mr-2' />
          )}
          {props.pack ? 'Continue' : 'Create Account'}
        </button>

        {(!registerFor || registerFor === 'subscription') && !price?.amount && (
          <div className='mt-5 w-full text-center text-sm text-icon-gray'>
            No Credit Card Required
          </div>
        )}
      </form>

      <div className='w-full'>
        <div className='w-full text-center text-sms font-medium text-icon-gray'>
          Have an account?{' '}
          <Link
            to={{
              pathname: '/login',
              search: window.location.search,
            }}
            className='text-primary'
          >
            Sign in
          </Link>
        </div>

        <div className='mt-5'>
          <LegalDisclaimer downplay />
        </div>
      </div>
    </div>
  );
}

function RedirectToSlackAuth() {
  useEffectOnce(() => {
    const run = async () => {
      window.location.href = await SlackUtils.GenerateSlackInstallURL({
        scenario: 'register',
        excludeSearchKeys: ['method'],
      });
    };

    run();
  });

  return <GlobalLoading />;
}

function useLoadData() {
  const { productId, priceId, packId, tagId } =
    useLoaderData<typeof clientLoader>();

  return useSWRImmutable(['/register', priceId, packId, tagId], async () => {
    const loadGamePack = async () => {
      if (!packId) return null;
      const resp = await apiService.gamePack.getGamePackById(packId);
      return resp.data.gamePack;
    };

    const loadProduct = async () => {
      const resp = await apiService.product.getPublicProducts();
      const product =
        resp.data.published.find((p) => p.id === productId) ||
        resp.data.published.find((p) => p.id === resp.data.defaultProductId);
      if (!product) throw new Error('Product not found');
      const price =
        product.prices?.find((p) => p.id === priceId) ||
        product.prices?.find((p) => !p.archived && p.amount === 0);
      return { product, price };
    };

    const loadTag = async () => {
      if (!tagId) return null;
      const resp = await apiService.tag.getTagBySlug(tagId);
      return resp.data.tag;
    };

    const [pack, { product, price }, tag] = await Promise.all([
      loadGamePack(),
      loadProduct(),
      loadTag(),
    ]);

    return { product, price, pack, tag };
  });
}

function Container(props: {
  pack: Nullable<DtoGamePack>;
  price: Nullable<ModelsPrice>;
  tag: Nullable<Tag>;
}) {
  const { registerFor, firstName, lastName, method, email, headcount } =
    useLoaderData<typeof clientLoader>();

  const [instantRegistering, setInstantRegistering] = useState(() =>
    OnboardingUtils.ShouldInstantRegistration(registerFor, email)
  );

  useSlackInstalledCallbackModal();
  const handleRegister = useHandleRegister();

  const { call: handleSubmit, state } = useLiveAsyncCall(
    async (email: string) => {
      const params = new URLSearchParams(window.location.search);
      const queries = Object.fromEntries(params.entries());
      await handleRegister({
        email,
        firstName: firstName,
        lastName: lastName,
        activate: OnboardingUtils.ShouldActivate(
          registerFor,
          !!firstName && !!lastName
        ),
        useEmailAsOrgName: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        queries,
      });
    }
  );

  // If the email is provided, we will register the user instantly
  useEffectOnce(() => {
    const run = async () => {
      if (!email) return;
      if (!instantRegistering) return;

      await handleSubmit(email);
      // if there is no error, user will be redirected to the next page
      // automatically. So we don't need to setInstantRegistering(false)
      // here in case of the flashing. If there is an error, we will show
      // the error message to the user.
      if (state.error) {
        setInstantRegistering(false);
      }
    };
    run();
  });

  if (instantRegistering) return <GlobalLoading debug='instant-register' />;

  if (method === 'slack') {
    return <RedirectToSlackAuth />;
  }

  return (
    <OnboardingPageLayout withBackground>
      <div className='w-full flex justify-center items-start gap-10'>
        {registerFor === 'coldOutreach' && <ColdOutreachPanel />}

        <RegistrationForm
          pack={props.pack}
          price={props.price}
          headcount={headcount}
          registerFor={registerFor}
          initEmail={email}
          onSubmit={handleSubmit}
          error={state.error}
        />

        {registerFor !== 'coldOutreach' && (
          <RightPanel pack={fromDTOGamePack(props.pack)} tag={props.tag} />
        )}
      </div>
    </OnboardingPageLayout>
  );
}

function Bootstrap() {
  const user = useUser({ init: true });
  const isUserLoaded = useIsUserLoaded();
  const { data, isLoading } = useLoadData();
  const { registerFor, email, firstName, trial, trialDays } =
    useLoaderData<typeof clientLoader>();
  const [trialOfferShown, setTrialOfferShown] = useState(false);

  if (isLoading || !isUserLoaded)
    return <GlobalLoading debug='register-bootstrap' />;
  if (user.id) {
    const postLoginRedirectPath = OnboardingUtils.GetPostLoginRedirectPath(
      registerFor,
      data?.pack
    );
    return (
      <>
        <GlobalLoading debug='register-bootstrap-post-logged-in' />
        <Navigate
          to={{
            pathname: postLoginRedirectPath,
            search: window.location.search,
          }}
          replace
        />
      </>
    );
  }

  if (
    (!registerFor || registerFor === 'subscription') &&
    !!email &&
    firstName &&
    trial &&
    !!data?.product.trialPeriodDays &&
    !trialOfferShown
  ) {
    return (
      <TrialOffer
        firstName={firstName}
        trialDays={
          trialDays ? Math.min(trialDays, 30) : data.product.trialPeriodDays
        }
        onConfirm={() => setTrialOfferShown(true)}
      />
    );
  }
  return <Container pack={data?.pack} price={data?.price} tag={data?.tag} />;
}

export const handle: ExternalScriptsHandle = {
  scripts: () => {
    return [...hubspotScripts(), ...gaScripts()];
  },
};

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);

  const registerFor = OnboardingUtils.ParseRegisterFor(
    url.searchParams.get('register-for')
  );
  const method = url.searchParams.get('method');
  const productId = url.searchParams.get('product-id');
  const priceId = url.searchParams.get('price-id');
  const trialDays = OnboardingUtils.ParseTrialDays(url.searchParams.get('td'));
  const trial = trialDays || booleanify(url.searchParams.get('trial'));
  const headcount = url.searchParams.get('headcount');
  const packId = url.searchParams.get('pack-id');
  const tagId = url.searchParams.get('tag-id');
  const email = url.searchParams.get('email');
  const firstName = url.searchParams.get('first-name');
  const lastName = url.searchParams.get('last-name');
  const youtubeVid = url.searchParams.get('youtube-vid');

  return {
    registerFor,
    method,
    productId,
    trial,
    trialDays,
    headcount,
    priceId,
    packId,
    tagId,
    email,
    firstName,
    lastName,
    youtubeVid,
  };
}

export function Component() {
  useTitle(makeTitle('Sign Up'));

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <ConfirmCancelModalProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <GAIdentify />
      <ClarityInstall />
      <HubspotIdentify email={getQueryParam('email') || ''} />
      <HubspotTrackPageView />
      <Bootstrap />

      <ConfirmCancelModalRoot />
    </ProvidersList>
  );
}
